<script setup>
import Checkbox from '@/Components/Form/Checkbox.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/InputError.vue'
import InputLabel from '@/Components/InputLabel.vue'
import ButtonPrimary from '@/Components/Buttons/ButtonPrimary.vue'
import TextInput from '@/Components/TextInput.vue'
import {Head, Link, useForm} from '@inertiajs/vue3'

defineProps({
	canResetPassword: {
		type: Boolean,
	},
	status: {
		type: String,
	},
})

const form = useForm({
	email: '',
	password: '',
	remember: false,
})

const submit = () => {
	form.post(route('login'), {
		onFinish: () => form.reset('password'),
	})
}
</script>

<!--<template>-->
<!--    &lt;!&ndash;-->
<!--      This example requires updating your template:-->

<!--      ```-->
<!--      <html class="h-full bg-gray-900">-->
<!--      <body class="h-full">-->
<!--      ```-->
<!--    &ndash;&gt;-->
<!--    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">-->
<!--        <div class="sm:mx-auto sm:w-full sm:max-w-sm">-->
<!--            <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=primary&shade=500" alt="Your Company" />-->
<!--            <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">Sign in to your account</h2>-->
<!--        </div>-->

<!--        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">-->
<!--            <form class="space-y-6" action="#" method="POST">-->
<!--                <div>-->
<!--                    <label for="email" class="block text-sm font-medium leading-6">Email address</label>-->
<!--                    <div class="mt-2">-->
<!--                        <input id="email" name="email" type="email" autocomplete="email" required="" class="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6" />-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div>-->
<!--                    <div class="flex items-center justify-between">-->
<!--                        <label for="password" class="block text-sm font-medium leading-6">Password</label>-->
<!--                        <div class="text-sm">-->
<!--                            <a href="#" class="font-semibold text-primary-400 hover:text-primary-300">Forgot password?</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mt-2">-->
<!--                        <input id="password" name="password" type="password" autocomplete="current-password" required="" class="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6" />-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div>-->
<!--                    <button type="submit" class="flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500">Sign in</button>-->
<!--                </div>-->
<!--            </form>-->

<!--            <p class="mt-10 text-center text-sm text-gray-400">-->
<!--                Not a member?-->
<!--                {{ ' ' }}-->
<!--                <a href="#" class="font-semibold leading-6 text-primary-400 hover:text-primary-300">Start a 14 day free trial</a>-->
<!--            </p>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->




<template>
	<GuestLayout>
		<Head title="Log in" />

		<div
			v-if="status"
			class="mb-4 font-medium text-sm text-green-600"
		>
			{{ status }}
		</div>

		<form @submit.prevent="submit">
			<div>
				<InputLabel
					for="email"
					value="E-mail"
				/>

				<TextInput
					id="email"
					v-model="form.email"
					type="email"
					class="mt-1 block w-full text-black"
					required
					autofocus
					autocomplete="username"
				/>

				<InputError
					class="mt-2"
					:message="form.errors.email"
				/>
			</div>

			<div class="mt-4">
				<InputLabel
					for="password"
					value="Wachtwoord"
				/>

				<TextInput
					id="password"
					v-model="form.password"
					type="password"
					class="mt-1 block w-full text-black"
					required
					autocomplete="current-password"
				/>

				<InputError
					class="mt-2"
					:message="form.errors.password"
				/>
			</div>

			<div class="block mt-4">
				<label class="flex items-center">
					<Checkbox
						v-model:checked="form.remember"
						name="remember"
					/>
					<span class="ml-2 text-sm text-gray-600">Onthouden</span>
				</label>
			</div>

			<div class="flex items-center justify-end mt-4">
				<Link
					v-if="canResetPassword"
					:href="route('password.request')"
					class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
				>
					Wachtwoord vergeten?
				</Link>

				<ButtonPrimary
					class="ml-4"
					:class="{ 'opacity-25': form.processing }"
					:disabled="form.processing"
				>
					Login
				</ButtonPrimary>
			</div>
		</form>
	</GuestLayout>
</template>
